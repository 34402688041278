<template>
    <div>
        <TinyNavigation :prev="{}" class="pt-6 px-6" />
        <Modal v-model:isOpen="isModalOpen">
            <div>
                <div class="mt-3 text-center sm:mt-5">
                    <h3 class="font-bold text-2xl text-white" id="modal-headline">
                        {{ t('not_enough_points') }}
                    </h3>
                    <router-link :to="{name: 'points.buy'}">
                        <span class="block w-full py-8 rounded-md shadow-sm">
                            <button type="button" class="button w-full">
                                {{ t('buy_points') }}
                            </button>
                        </span>
                    </router-link>
                </div>
            </div>
            <div class="mt-5 sm:mt-6">
                <span @click="isModalOpen = false" class="flex w-full rounded-md shadow-sm">
                    <button type="button" class="button-transparent">
                        {{ t('back') }}
                    </button>
                </span>
            </div>
        </Modal>

        <div class="px-6 pt-10">
            <template v-if="!empty">
                <h1 class="heading-1 pb-4 whitespace-pre-line" v-text="t('choose_package')" />

                <a v-for="product in products" :key="product.id" @click.prevent="clickProduct(product)" class="cursor-pointer">
                    <div class="bg-white border-4 border-white rounded mb-4 p-6 hover:bg-gray-200 hover:border-scorelitorange transition duration-200 group">
                        <div class="font-bold pb-4 flex items-center justify-between">
                            <span class="text-scorelitorange font-semibold text-xl">{{ product.name }}</span>
                            <div class="z-20 cursor-pointer text-gray-200 text-sm bg-gray-200 hover:bg-gray-700 hover:text-white transition duration-200 py-2 px-3 rounded group-hover:bg-gray-400 group-hover:text-black">
                                <span class="leading-1 text-gray-800 group-hover:text-gray-900 font-medium">{{ humanReadablePrice(product.product_price*100) }}</span>
                            </div>
                        </div>
                        <p class="text-gray-700 font-normal text-sm group-hover:text-gray-900">{{ product.trainee_description }}</p>
                    </div>
                </a>
            </template>
            <template v-else>
                <p class="text-white text-center pt-20">{{ t('no_packages_available') }}</p>

                <router-link :to="{name: 'dashboard'}">
                    
                    <span class="block w-full p-8 rounded-md shadow-sm">
                        <button type="button" class="button w-full">
                            {{ t('to_experts') }}
                        </button>
                    </span>
                    
                </router-link>
            </template>
        </div>
    </div>
</template>

<script>
import Modal from "@/wrappers/partials/Modal";
import TinyNavigation from "@/components/TinyNavigation";
import { indexProducts } from "@/helpers/api/ProductHelper";
import store from "@/store";
import useI18n from "@/modules/globalI18n";
import { humanReadablePrice } from "@/helpers/CurrencyHelper"

export default {
    name: "ReviewCreateSelectProduct",
    setup() {
        return useI18n({
            viewPrefix: "ReviewCreateSelectProduct"
        });
    },
    components: { Modal, TinyNavigation },
    created(){
        indexProducts(this.$route.query.match_id, this.$route.query.coach_id)
            .then((data) => {
                this.products = data;
                if (!data || data.length == 0){
                    this.empty = true;
                }
                // console.log(data);
            })
    },
    methods: {
        humanReadablePrice,
        clickProduct(product){
            if (product.token_price > this.user.token_balance){
                this.isModalOpen = true;
                return ;
            }
            this.$router.push({name: 'review.create', query: {...this.$route.query, product_id: product.id}})
        }
    },
    data(){
        return {
            products: [],
            empty: false,
            isModalOpen: false,
        }
    },
    computed: {
        user: () => {
            return store.getters['getUser'];
        },
    },
}
</script>
