import axios from 'axios';

const index_product_path = "v1/trainee/product/"; // + {match?}/{coach?} // get 

function indexProducts(match_id = null, coach_id = null)
{
    let url = index_product_path + match_id + '/' + coach_id;
    
    return new Promise((resolve, reject) => {
        axios.get(url)
            .then((response)=>{
                resolve(response.data.products);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

export { indexProducts };